* {
  font-family: 'Baloo 2', cursive, sans-serif !important;
}

body {
  margin: 0;
}

.color-legend h1 {
  margin: 0;
  font-size: 1rem;
}

.color-legend ul {
  padding: 0;
  margin: 0;
  margin-top: 4px;
}

.color-legend li {
  display: flex;
  align-items: center;
  gap: 8px;
}

.color-legend div {
  width: 32px;
  height: 16px;
  border-radius: 6px;
}

/* Order table fix */
.ant-table-row-expand-icon.ant-table-row-expand-icon-spaced {
  display: none;
}

/* Form item fix */
.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-tabs .ant-tabs-tab {
  padding: 8px 0 !important;
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

/* Invoice Editor */
.invoice-editor {
  font-family: Arial, Helvetica, sans-serif !important;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
}

.invoice-editor > section {
  max-height: calc(100vh - 64px - 52px);
}

.invoice-editor h1 {
  margin: 0;
  font-size: 18px;
}

.invoice-editor table {
  border-spacing: 0;
  width: 100%;
}

.invoice-editor td {
  margin: 0;
  padding: 6px 8px;
  border-bottom: 1px solid #888;
  vertical-align: top;
}

.invoice-editor tr:last-child td {
  border: 0;
}

.invoice-editor th {
  border-bottom: 1px solid #333;
}

.invoice-editor .row-alternation tr:nth-child(even) {
  background: #ddd;
}

.invoice-editor .row-alternation tr:last-child td {
  border-bottom: 0;
}

.invoice-editor hr {
  width: 100%;
  border: 0;
  outline: 1px solid #ccc;
}

.invoice-editor p.editor-preview-item {
  word-break: break-all;
  margin: 0;
}

.invoice-editor .editor-preview-item {
  position: relative;
}

.invoice-editor .editor-preview-item.box {
  padding: 8px;
}

.invoice-editor [data-selected='true'] {
  outline: 2px solid rgb(64, 155, 253) !important;
}
.invoice-editor .editor-preview-item.mouse-hover {
  outline: 1px solid rgb(64, 155, 253);
}
.invoice-editor .drop-area.drag-hover {
  outline: 2px solid rgb(152, 0, 0);
}

.grid-background {
  background: #fefefe;
  background-image: linear-gradient(#d1d1d1 1px, transparent 1px), linear-gradient(90deg, #d1d1d1 1px, transparent 1px);
  background-size: 5mm 5mm;
  background-repeat: repeat;
}

#spa-diary .dx-scheduler-header {
  display: none;
}

#spa-diary .dx-scheduler-cell-sizes-horizontal {
  width: 40px !important;
}

#weekScheduler .dx-scheduler-header {
  display: none;
}

#weekScheduler .dx-scheduler-appointment-content {
  padding: 0;
}
